import $ from 'jquery';
import autoStore from "./autoStore";

//----------------------------------------------------
// Indexed DB package for inspection module
//----------------------------------------------------

/**
 * These need global scope, therefore var type
 */
var db;
var QAO_DB_NAME = 'inspections';
var QAO_DB_STORE_AREA = 'areas';
var QAO_DB_STORE_QA = 'q-and-a';
var QAO_DB_STORE_IMG = 'images';
var QAO_LOCK_COOKIE_NAME = 'idb-lock'
var QAO_ALL_FETCHED = false;
export var QAO_IMG_STORE_TYPE = 'ArrayBuffer';
export var QAO_SCORES_COUNT_MAX = [null, "0", "1", "2", "3"];
export var QAO_SCORES_COUNT_SCORE = ["1", "2", "3"];
export var QAO_QA_SCORE_MAX = 3;
export var QAO_SCORE_AREA_MAX = 0;
export var QAO_SCORE_AREA_CURRENT = 0;

/**
 * Update version number (integer only) when changing structure
 * @type {number}
 */
var QAO_DB_VERSION = 2;

/**
 * Open Indexed DB, create pointers for object stores and update structure if necessary
 * Required to call once upon page load if you want to use Indexed DB
 */
export function openDb() {
    var req = indexedDB.open(QAO_DB_NAME, QAO_DB_VERSION);

    /**
     * Basically the DB structure
     * Update QAO_DB_VERSION if you change this
     * @param evt
     */
    req.onupgradeneeded = function (evt) {
        var storeArea;
        try {
            storeArea = req.transaction.objectStore(QAO_DB_STORE_AREA);
        } catch (e) {
            storeArea = evt.currentTarget.result.createObjectStore(
                QAO_DB_STORE_AREA, {keyPath: 'id', autoIncrement: true}
            );
            storeArea.createIndex('areaId', 'areaId', {unique: true});
            storeArea.createIndex('areaName', 'areaName', {unique: false});
        }

        var storeQa;
        try {
            storeQa = req.transaction.objectStore(QAO_DB_STORE_QA);

        } catch (e) {
            storeQa = evt.currentTarget.result.createObjectStore(
                QAO_DB_STORE_QA, {keyPath: 'id', autoIncrement: true}
            );
            storeQa.createIndex('qaId', 'qaId', {unique: true});
            storeQa.createIndex('areaId', 'areaId', {unique: false});
            storeQa.createIndex('processed', 'processed', {unique: false});
        }

        var storeImg;
        try {
            storeImg = req.transaction.objectStore(QAO_DB_STORE_IMG);

        } catch (e) {
            storeImg = evt.currentTarget.result.createObjectStore(
                QAO_DB_STORE_IMG, {keyPath: 'id', autoIncrement: true}
            );
            storeImg.createIndex('qaId, deleted', ['qaId', 'deleted'], {unique: false});
            storeImg.createIndex('processed', 'processed', {unique: false});
            storeImg.createIndex('dbId', 'dbId', {unique: false});
        }
    };

    req.onsuccess = function () {
        // Equal to: db = req.result;
        db = this.result;
    };
    req.onerror = function (evt) {
        console.log("openDb error:", evt.target.errorCode);
    };
    req.onblocked = function (evt) {
        console.log("openDb Connection blocked", evt);
    }

    const interval = setInterval(function () {
        if (req.readyState === "pending") {
            alert('Something went wrong, please shutdown and restart your browser');
        }
        clearInterval(interval);

    }, 3000);
    return req;
}


/**
 * Load all Q and A for given inspection into Indexed DB
 * @param url
 * @param force
 */
export function fetchAndStoreQA(url, force) {
    if (QAO_ALL_FETCHED) {
        $('#loadingModal').hide();
        return true;
    }
    let currentInspection = parseInt($('[name=inspectionId]').val());
    let serverTimestamp = parseInt($('[name=serverTimestamp]').val());
    let cookie = getLockCookie();
    let goFetch;
    let askFirst = false;
    if (!cookie) {
        setLockCookie();
        goFetch = true;
    } else {
        if (cookie.inspectionId != currentInspection) {
            setLockCookie();
            goFetch = true;
        } else if (cookie.timeStamp !== serverTimestamp) {
            goFetch = false;
        } else {
            goFetch = true;
        }
    }
    if (force === true) {
        goFetch = true;
    }
    if (goFetch === false) {
        let qaGetter = getUnprocessedQa();
        qaGetter.then(function (qa) {
            if (qa.length === 0) {
                let imgGetter = getUnprocessedImages();
                imgGetter.then(function (img) {
                    if (img.length === 0) {
                        // Nothing new on device so fetch anyway
                        fetchAndStoreQA(url, true);
                    } else {
                        // Unprocessed images found, ask user what to do
                        askFirst = true;
                    }
                });
            } else {
                // Unprocessed Q&A found, ask user what to do
                askFirst = true;
            }
            if (askFirst) {
                if (confirm(window.inspectionTranslations.fetchPushUnprocessedQuestion)) {
                    let store = autoStore(true);
                    store.then(function () {
                        // Since this is an edge-case, we settle for a page refresh after 1 second to make sure everything will be reset to given state.
                        setTimeout(function () {
                            window.location.reload(true)
                        }, 1000);
                    });
                } else {
                    if (confirm(window.inspectionTranslations.fetchDitchUnprocessedQuestion)) {
                        goFetch = true;
                        fetchAndStoreQA(url, true);
                    } else {
                        window.location.href = $('#cancelWarning a').attr('href');
                        return false;
                    }
                }
            }
        });
    }
    if (goFetch) {
        clearAreas();
        clearQandQ();
        clearImages();

        $.ajax({
            method: 'GET',
            url: url,
            dataType: 'json',
            success: (list) => {
                const areaIds = Object.keys(list);
                const lastAreaId = areaIds[areaIds.length - 1];
                $.each(list, function (key, area) {
                    const answerList = Object.keys(area.answers);
                    const lastAnswer = answerList[answerList.length - 1];
                    addArea(key, area.areaName)
                    $.each(area.answers, function (qaId, answerObject) {
                        addQa(answerObject, key);

                        if ((key == lastAreaId) && (qaId == lastAnswer)) {
                            $('#loadingModal').hide();
                        }
                    });
                });
                setLockCookie();
                QAO_ALL_FETCHED = true;
            },
            error: () => {
            },
            xhr: function () {
                var xhr = $.ajaxSettings.xhr();
                xhr.onprogress = function (e) {
                    if (e.loaded > 0) {
                        $('#bytesReceived').text(e.loaded + ' bytes').parent('p').removeClass('d-none');
                    }
                };
                return xhr;
            }
        });
    }
}


/**
 * Create new area in DB
 * @param {int} id
 * @param {string} name
 */
export function addArea(id, name) {
    var obj = {
        areaId: id,
        areaName: name,
        processed: 0,
    };
    var store = getObjectStore(QAO_DB_STORE_AREA, 'rw');
    try {
        store.add(obj);
    } catch (e) {
        console.log('error adding area: ' + e.name);
    }
}

/**
 * Create new Q&A item in DB
 * @param qaObject
 * @param areaId
 */
export function addQa(qaObject, areaId) {
    var obj = {
        qaId: qaObject.id,
        areaId: areaId,
        questionId: qaObject.question_id,
        question: qaObject.question,
        explanation: qaObject.explanation,
        answer: qaObject.answer,
        comment: qaObject.comment,
        processed: 1, // Q&A always come from server so default processed
    };
    var store = getObjectStore(QAO_DB_STORE_QA, 'rw');
    try {
        store.add(obj);
        if (qaObject.documents.length > 0) {
            $.each(qaObject.documents, function (key, doc) {
                addImage(qaObject.id, base64ToBlob(doc.blob, doc.mime_type), 1, doc.uuid);
                window.allQaProcessed = true;
                window.allImagesProcessed = true;
            });
        }
    } catch (e) {
        console.log('error adding Q and A: ' + e.name);
    }
}

/**
 * Add an image BLOB to a question
 * @param qaId
 * @param image
 * @param processed
 * @param dbId uuid or -1
 */
export function addImage(qaId, image, processed, dbId) {
    window.allImagesProcessed = false;
    return new Promise(async function (resolve) {
        var obj = {
            qaId: parseInt(qaId),
            dbId: dbId,
            blob: null,
            deleted: 0,
            processed: processed,
        };

        // Decide on storage type
        switch (QAO_IMG_STORE_TYPE) {
            case 'Blob':
                obj.blob = image;
                break;
            case 'ArrayBuffer':
                obj.blob = await blobToArrayBuffer(image);
                break;
            default:
                alert('Can\'t store image');
                return;
        }

        var store = getObjectStore(QAO_DB_STORE_IMG, 'rw');
        try {
            const req = store.add(obj);
            req.onsuccess = function (evt) {
                resolve(evt.target.result);
                setLockCookie();
            }
            req.onerror = function (evt) {
                reject(new Error('There was an error storing the image.'));
            }
        } catch (e) {
            console.log('error adding image: ' + e.name);
            console.log(obj);
            reject(e);
        }
    });
}

export function arrayBufferToBlob(buffer, type = 'image/jpeg') {
    return new Blob([buffer], {type: type});
}

export function blobToArrayBuffer(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('loadend', () => {
            resolve(reader.result);
        });
        reader.addEventListener('error', reject);
        reader.readAsArrayBuffer(blob);
    });
}

/**
 * Mark images as deleted and to be processed
 * @param key
 */
export function deleteImage(key) {
    var store = getObjectStore(QAO_DB_STORE_IMG, 'rw');
    var req = store.get(parseInt(key));
    req.onsuccess = function (evt) {
        window.allQaProcessed = false;
        var data = evt.target.result;
        if (data.processed === 0) {
            // Image exists only in object store, simply delete here
            store.delete(parseInt(key));
        } else {
            // Image exists on server, update delete pointer
            data.deleted = 1;
            data.processed = 0;
            var reqUpdate = store.put(data);
            reqUpdate.onerror = function () {
                console.log('error deleting image');
            };
        }
    }
}

/**
 * Get all Q an A objects for given Area
 * @param areaId
 * @returns {Promise<unknown>}
 */
export function getAreaQandA(areaId) {
    return new Promise(function (resolve, reject) {
        var store = getObjectStore(QAO_DB_STORE_QA, 'r');
        var req = store.index('areaId').getAll(areaId.toString());
        req.onsuccess = function (evt) {
            QAO_SCORE_AREA_MAX = 0;
            QAO_SCORE_AREA_CURRENT = 0;
            let allQa = evt.target.result;
            allQa.forEach(qa => {
                if (QAO_SCORES_COUNT_MAX.includes(qa.answer)) {
                    QAO_SCORE_AREA_MAX += QAO_QA_SCORE_MAX;
                }
                if (QAO_SCORES_COUNT_SCORE.includes(qa.answer)) {
                    QAO_SCORE_AREA_CURRENT += parseInt(qa.answer);
                }
            });
            resolve(evt.target.result);
        }
        req.onerror = function (evt) {
            reject(evt);
        }
    });
}

/**
 * Get images for given Q&A that aren't marked as deleted
 * @param qaId
 * @returns {Promise<unknown>}
 */
export function getImages(qaId) {
    return new Promise(function (resolve, reject) {
        var store = getObjectStore(QAO_DB_STORE_IMG, 'r');
        var req = store.index('qaId, deleted').getAll([qaId, 0]);
        req.onsuccess = function (evt) {
            var images = evt.target.result;
            // If QAO_PREFERRED_IMG_STORE_TYPE is ArrayBuffer, convert to Blob
            if (QAO_IMG_STORE_TYPE === 'ArrayBuffer') {
                images = images.map(function (image) {
                    image.blob = arrayBufferToBlob(image.blob);
                    return image;
                });
            }
            resolve(images);
        }
        req.onerror = function (evt) {
            reject(evt);
        }
    });
}

/**
 * Get basic area info for given id
 * @param areaId
 * @returns {Promise<unknown>}
 */
export function getAreaInfo(areaId) {
    return new Promise(function (resolve, reject) {
        var store = getObjectStore(QAO_DB_STORE_AREA, 'r');
        var req = store.index('areaId').get(areaId.toString());
        req.onsuccess = function (evt) {
            resolve(evt.target.result);
        }
        req.onerror = function (evt) {
            reject(evt);
        }
    });
}

/**
 * Check if there is info in the indexed DB at all
 * @returns {Promise<unknown>}
 */
export function checkInfo() {
    return new Promise(function (resolve, reject) {
        var store = getObjectStore(QAO_DB_STORE_QA, 'r');
        var req = store.openCursor();
        req.onsuccess = function (evt) {
            resolve(evt.target.result);
        }
        req.onerror = function (evt) {
            reject(evt);
        }
    });
}

export function getUnprocessedQa() {
    return new Promise(function (resolve, reject) {
        var store = getObjectStore(QAO_DB_STORE_QA, 'r');
        var req = store.index('processed').getAll(0);
        req.onsuccess = function (evt) {
            let rawData = evt.target.result;
            if (rawData.length > 0) {
                rawData.forEach(function (row) {
                    delete row.question;
                    delete row.questionId;
                    delete row.id;
                    delete row.areaId;
                    delete row.explanation;
                    delete row.key;
                    delete row.processed;
                });
            }
            resolve(rawData);
        }
        req.onerror = function (evt) {
            reject(evt);
        }
    });
}

export function getUnprocessedImages() {
    return getUnprocessedChanges(QAO_DB_STORE_IMG);
}

/**
 * Update question id to set answer
 * @param qaId
 * @param answer
 */
export function setAnswer(qaId, answer) {
    window.allQaProcessed = false;
    $('#statusIconUnsent').show();
    $('#statusIconSent').hide();

    var store = getObjectStore(QAO_DB_STORE_QA, 'rw');
    var req = store.index('qaId').get(qaId);
    req.onsuccess = function (evt) {
        setLockCookie();
        var data = evt.target.result;
        data.answer = answer.toString();
        data.processed = 0;
        var reqUpdate = store.put(data);
        reqUpdate.onerror = function () {
            // Do something with the error
            console.log('error updating');
        };
    }
}

export function setQaProcessed(qaId) {
    var store = getObjectStore(QAO_DB_STORE_QA, 'rw');
    var req = store.index('qaId').get(qaId);
    req.onsuccess = function (evt) {
        var data = evt.target.result;
        data.processed = 1;
        var reqUpdate = store.put(data);
        reqUpdate.onerror = function () {
            // Do something with the error
            console.log('error updating');
        };
    }
}

export function setImgProcessed(key) {
    var store = getObjectStore(QAO_DB_STORE_IMG, 'rw');
    var req = store.get(key);
    req.onsuccess = function (evt) {
        var data = evt.target.result;
        data.processed = 1;
        var reqUpdate = store.put(data);
        reqUpdate.onerror = function () {
            // Do something with the error
            console.log('error updating');
        };
    }
}

/**
 * Update question id to set comment
 * @param qaId
 * @param comment
 */
export function setComment(qaId, comment) {
    window.allQaProcessed = false;
    var store = getObjectStore(QAO_DB_STORE_QA, 'rw');
    var req = store.index('qaId').get(qaId);
    req.onsuccess = function (evt) {
        setLockCookie();
        var data = evt.target.result;
        if (typeof (comment) !== undefined && (comment.length > 0)) {
            data.comment = comment.toString();
        } else {
            data.comment = null;
        }
        data.processed = 0;
        var reqUpdate = store.put(data);
        reqUpdate.onerror = function () {
            // Do something with the error
            console.log('error updating');
        };
    }
}

/**
 * Return timebased filename for qaId
 * @param qaId
 * @param i
 * @returns {string}
 */
export function createTimestampFilename(qaId, i) {
    const currenDate = new Date();
    const curr_date = currenDate.getDate();
    const curr_month = currenDate.getMonth();
    const curr_year = currenDate.getFullYear();
    const timeStamp = curr_year + '-' + curr_month + '-' + curr_date;
    return 'Upload-' + qaId + '-' + timeStamp + '-' + i + '.jpg';
}

/**
 * Clear & destroy functions
 */

export function destroyDB() {
    clearAreas();
    clearQandQ();
    clearImages();
}

export function clearAreas() {
    clearObjectStore(QAO_DB_STORE_AREA);
}

export function clearQandQ() {
    clearObjectStore(QAO_DB_STORE_QA);
}

export function clearImages() {
    clearObjectStore(QAO_DB_STORE_IMG);
}

export function setLockCookie() {
    let date = new Date();
    let cookieContent = {
        timeStamp: Date.now(),
        userId: $('#js-user').data('user-id'),
        accountId: $('#js-accountlocation').data('account-id'),
        inspectionId: parseInt($('[name=inspectionId]').val()),
    }
    document.cookie = QAO_LOCK_COOKIE_NAME + "=" + JSON.stringify(cookieContent) + ";max-age=" + (30 * 24 * 3600) + "; path=/;SameSite=Lax";
}

export function getLockCookie() {
    const search = QAO_LOCK_COOKIE_NAME + '=';
    const allCookies = decodeURIComponent(document.cookie).split('; ');
    let lockCookie;
    allCookies.forEach(val => {
        if (val.indexOf(QAO_LOCK_COOKIE_NAME) === 0) {
            lockCookie = JSON.parse(val.substring(search.length));
        }
    })
    return lockCookie;
}

/**
 * "private" functions
 */
function clearObjectStore(storeName) {
    var store = getObjectStore(storeName, 'rw');
    var req = store.clear();
    req.onsuccess = function () {
        // console.log("Store " + storeName + " cleared");
    };
    req.onerror = function (evt) {
        console.error("clearObjectStore:", evt.target.errorCode);
    };
}

/**
 * Get unprocessed changes for given object store
 * @param storeName
 * @returns {Promise<unknown>}
 */
function getUnprocessedChanges(storeName) {
    return new Promise(function (resolve, reject) {
        var store = getObjectStore(storeName, 'r');
        var req = store.index('processed').getAll(0);
        req.onsuccess = function (evt) {
            resolve(evt.target.result);
        }
        req.onerror = function (evt) {
            reject(evt);
        }
    });
}

/**
 * Get either object store in readonly or readwrite mode
 * @param {string} store_name
 * @param {string} mode either "r" (readonly) or "rw" (readwrite)
 */
function getObjectStore(store_name, mode) {
    if (mode === 'rw') {
        mode = 'readwrite';
    } else {
        mode = 'readonly';
    }

    var tx = db.transaction(store_name, mode);
    return tx.objectStore(store_name);
}

/**
 * Convert base64 encoded file to blob object
 * @param base64
 * @param mime
 * @returns {Blob}
 */
function base64ToBlob(base64, mime) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], {type: mime});
}
