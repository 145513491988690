import $ from 'jquery';

/**
 * Handle online check
 */
export default function () {

    const iconTrue = `<i class="bi bi-wifi font-size-xx-large color-merieux-green"></i>`;
    const iconFalse = `<i class="bi bi-wifi-off font-size-xx-large color-merieux-red"></i>`;
    const $onlineElement = $('#js-online');
    const $notification = $('#js-notification');
    const $resendBtn = $('#step3_submit');

    function fetchOnline() {
        if ($onlineElement.length) {
            try {
                fetch(checkUrl, {method: 'HEAD'})
                    .then(() => {
                        $onlineElement.html(iconTrue);
                        $notification.text('Online').addClass('online').removeClass('offline');
                        $resendBtn.removeClass('disabled').removeAttr('disabled');
                    }).catch(() => {
                    $onlineElement.html(iconFalse);
                    $notification.text('Offline').addClass('offline').removeClass('online');
                    $resendBtn.addClass('disabled').attr('disabled', 'disabled');
                });
            } catch (e) {
                $onlineElement.html(e.toString().substring(0, 225)).addClass('color-merieux-red');
            }
        } else {
            console.error("Element with ID #js-online not found");
        }
    }

    fetchOnline();
    setInterval(() => fetchOnline(), 5000);
}