import $ from 'jquery';
import * as idb from "./idbStore";
import * as bootstrap from "bootstrap";
import onlineCheck from "./onlineCheck";

/**
 * Handle upload multi-files
 */
window.autoStoreBusy = false;
export default function autoStore(overwrite = false) {
    return new Promise(function (resolve) {
        if (window.autoStoreBusy) {
            resolve(true);
        }
        window.autoStoreBusy = true;

        // Q&A first
        let qaGetter = idb.getUnprocessedQa();
        qaGetter.then(function (unprocessedQa) {
            let postQaUrl = window.storeAnswerPath;
            if (overwrite) {
                postQaUrl += '/overwrite';
            }
            let qaPoster = postQa(unprocessedQa, postQaUrl);
            qaPoster.then(function () {
                // Q&A done, now do the images
                let imgGetter = idb.getUnprocessedImages();
                imgGetter.then(function (images) {
                    let postImgUrl = window.storeImgPath;
                    if (overwrite) {
                        postImgUrl += '/overwrite';
                    }
                    if (images.length > 0) {
                        postImageCount(images);
                        window.allImagesProcessed = false;
                        let batches = Math.ceil(unprocessedQa.length / 100);
                        let imgTotal = images.length + batches;
                        if (navigator.onLine) {
                            let imgPoster = postImages(images, postImgUrl, imgTotal, batches);
                            imgPoster.then(function () {
                                window.allImagesProcessed = true;
                                setStatusIcon();
                                resolve(true);
                            });
                        }
                    } else {
                        window.allImagesProcessed = true;
                        setStatusIcon();
                        resolve(true);
                    }
                });
            });
        });
    });
}

/**
 * Set status icon for synced data based on processed markers
 */
export function setStatusIcon() {
    if (window.allQaProcessed && window.allImagesProcessed) {
        $('#statusIconUnsent').hide();
        $('#statusIconSent').show();
        window.allStored = true;
    } else {
        $('#statusIconUnsent').show();
        $('#statusIconSent').hide();
    }
}

/**
 * Post unprocessed Q&A to the server if any
 * @param postQaUrl
 * @param unprocessedQa
 * @returns {Promise<unknown>}
 */
async function postQa(unprocessedQa, postQaUrl) {
    return new Promise(function (resolve, reject) {
        let total = unprocessedQa.length;
        const batchMax = 100; // QAO-1399 send in batches
        if (total > 0) {
            let batchSize = Math.min(total, batchMax);
            let sendQa = unprocessedQa.slice(0, batchSize);
            unprocessedQa = unprocessedQa.slice(batchSize);
            window.allQaProcessed = false;

            checkConnection().then(function () {
                $.ajax({
                    type: "POST",
                    url: postQaUrl,
                    dataType: "json",
                    data: {sendQa},
                    success: function () {
                        sendQa.forEach(qa => {
                            idb.setQaProcessed(qa.qaId);
                        });
                        resolve(postQa(unprocessedQa, postQaUrl));
                    },
                    error: function (request) {
                        reject(handlePostError(request));
                    }
                });
            });
        } else {
            window.allQaProcessed = true;
            setStatusIcon();
            resolve(true);
        }
    });
}

/**
 * Recursively post images to server if any
 * @param images
 * @param postImgUrl
 * @param imgTotal
 * @param batches
 * @returns {Promise<unknown>}
 */
function postImages(images, postImgUrl, imgTotal, batches) {
    return new Promise(async function (resolve, reject) {

        // progress bar percentage
        let imageCount = images.length;
        let max = imgTotal;
        let inProgress = max - imageCount - batches;
        let percentage;
        if (max === 0 || inProgress === 0) {
            percentage = 1;
        } else {
            percentage = ((inProgress / max) * 100).toFixed();
        }
        $('#js-server-upload-progress-bar').text((percentage) + '%').width((percentage) + '%');

        if (images.length > 0) {
            // check online
            checkConnection().then(function () {
                let img = images.pop();
                if (img.deleted === 1) {
                    // Deleted in Indexed DB so only delete
                    if (img.dbId !== "-1") {
                        // Deleted on Indexed DB, post deletion to server
                        $.ajax({
                            url: window.deleteImgPath + '/' + img.dbId,
                            success: function () {
                                idb.setImgProcessed(img.id);
                                resolve(postImages(images, postImgUrl, imgTotal, batches));
                            },
                            error: function (request) {
                                reject(handlePostError(request));
                            }
                        });
                    } else {
                        // Not even on the server so let it go
                        idb.setImgProcessed(img.id);
                        resolve(postImages(images, postImgUrl, imgTotal, batches));
                    }
                } else {
                    // New in Indexed DB, post to server
                    const fileData = new FormData();
                    const fileName = idb.createTimestampFilename(img.qaId, images.length);
                    if (idb.QAO_IMG_STORE_TYPE === 'ArrayBuffer') {
                        img.blob = idb.arrayBufferToBlob(img.blob);
                    }
                    fileData.append("document[" + img.qaId + "][]", img.blob, fileName);
                    $.ajax({
                        type: "POST",
                        url: postImgUrl,
                        dataType: "json",
                        contentType: false, // Not to set any content header
                        processData: false, // Not to process data
                        data: fileData,
                        success: function () {
                            idb.setImgProcessed(img.id);
                            resolve(postImages(images, postImgUrl, imgTotal, batches));
                        },
                        error: function (request) {
                            reject(handlePostError(request));
                        }
                    });
                }
            });
        } else {
            // No images left, be done with it
            resolve(true);
        }
    });
}

function postImageCount(images) {
    const qaId = images[0].qaId
    const count = images.filter(image => (image.deleted === 0 && image.processed === 0)).length;

    $.ajax({
        type: "POST",
        url: window.logImgCountPath,
        dataType: "json",
        data: {
            'count': count,
            'qaId': qaId,
        },
    });
}

/**
 * Handle ajax error when posting
 * @param request
 */
function handlePostError(request) {
    switch (request.status) {
        case 409:
            // Timestamp error
            if (confirm(window.inspectionTranslations.autoStoreServerTimestampQuestion)) {
                return autoStore(true);
            } else {
                // close modal, do nothing further
                closeStoreModal();
                return false;
            }
            break;
        case 400:
        case 401:
        case 403:
            // Auth related. Destroy the indexed DB to prevent data leaks
            idb.destroyDB();
            setTimeout(function () {
                window.location.href = submitAfterAutoStore + '/' + $('#submitLink').val();
            }, 1000);
            return false;
            break;
        case 404:
        case 406:
        case 407:
        case 408:
        case 410:
        case 415:
        case 429:
        case 500:
        case 501:
        case 502:
        case 503:
        case 504:
        case 505:
        case 506:
        case 510:
        case 511:
            // Client/server error, show notification and suggest to try again later
            showErrorModal();
            return false;
            break;
        default:
            // these shouldn't be here
            connectionFailed();
            return false;
            break;
    }
}

/**
 * Display generic error
 */
function showErrorModal() {
    closeStoreModal();
    let errorModal = new bootstrap.Modal(document.getElementById('errorModal'));
    errorModal.show();
}

/**
 * Bootstrap refuses simple somehow call so handle here
 */
function closeStoreModal() {
    $('#storeModal').hide();
    $('.modal-backdrop:last-of-type').remove();
    window.autoStoreBusy = false;
}

function checkConnection() {
    return new Promise(async function (resolve, reject) {
        try {
            fetch(checkUrl, {method: 'HEAD'})
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    connectionFailed();
                    reject(false);
                });
        } catch (error) {
            console.error('An error occurred while checking network speed:', error);
            reject(false);
        }
    })

}

function connectionFailed() {
    let storeModal = new bootstrap.Modal(document.getElementById('storeModal'));
    let offlineModal = new bootstrap.Modal(document.getElementById('offlineModal'));
    offlineModal.show();
    onlineCheck();
    storeModal.hide();
    reject(false);
}

$(document).ready(function () {
    $('#step3_submit').click(function () {
        window.autoStoreBusy = false;  // Reset flag to allow restarting autoStore process
        let store = autoStore(false);
        store.then(function () {
            window.location.href = submitAfterAutoStore + '/' + $('#submitLink').val();
        });
    });
});